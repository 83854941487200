import React from "react"

import Layout from '../layouts';
import SEO from '../components/SEO';
import { Grid, Column, Container } from '../components/Layout';
import Button from '../components/Button'
import Section from '../components/Section'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Grid>
        <Column width={[12]}>
          <Section variant="primary">
            <h1>PAGE NOT FOUND</h1>
            <p style={{paddingBottom: '32px'}}>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <Button url="/">Go to Home Page</Button>
          </Section>
        </Column>
      </Grid>
    </Container>
  </Layout>
)

export default NotFoundPage
